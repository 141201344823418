let showTime = document.querySelectorAll(".show-time");

for (var item of showTime) {
  item.addEventListener('click', (e)=>{
    e.preventDefault();
    document.querySelector("body").classList.toggle("time-is-visible");
  })
}

let showMemory = document.querySelectorAll(".show-memory");

for (var item of showMemory) {
  item.addEventListener('click', (e)=>{
    e.preventDefault();
    document.querySelector("body").classList.toggle("memory-is-visible");
  })
}

let showNumber = document.querySelectorAll(".show-number");

for (var item of showNumber) {
  item.addEventListener('click', (e)=>{
    e.preventDefault();
    document.querySelector("body").classList.toggle("number-is-visible");
  })
}

var tday=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
var tmonth=["01","02","03","04","05","06","07","08","09","10","11","12"];

function GetClock(){
	var d=new Date();
	var nday=d.getDay(),nmonth=d.getMonth(),ndate=d.getDate(),nyear=d.getFullYear();
	var nhour=d.getHours(),nmin=d.getMinutes(),nsec=d.getSeconds(),ap;
	
	if(ndate<=9) ndate="0"+ndate;
	if(nhour<=9) nhour="0"+nhour;
	if(nmin<=9) nmin="0"+nmin;
	if(nsec<=9) nsec="0"+nsec;
	
	var clocktext=""+nyear+"-"+tmonth[nmonth]+"-"+ndate+"T"+nhour+":"+nmin+":"+nsec+"";
	
	document.getElementById('clock').innerHTML=clocktext;
}

GetClock();
setInterval(GetClock,1000);


var elem = document.querySelector('.container');
var infScroll = new InfiniteScroll( elem, {
  // options
  path: '.more-items-link',
  append: '.entry',
  history: false,
  status: '.page-load-status',
});